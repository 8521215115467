import { EOL } from 'os';
import 'setimmediate';
import winston, { format, transports } from 'winston';
import type Transport from 'winston-transport';

import { type LoggerConfig } from './LoggerConfig';

export function createWinstonLogger(loggerConfig: LoggerConfig): winston.Logger {
    const { logLevel: level, datadog, logFormat } = loggerConfig;

    const winstonTransports: Transport[] = [consoleTransport(logFormat)];

    if (datadog) {
        const datadogFormat = format.combine(format.timestamp(), format.json());
        winstonTransports.push(dataDogTransport({ ...datadog, level, format: datadogFormat }));
    }

    return winston.createLogger({
        level,
        transports: winstonTransports,
    });
}

function consoleTransport(logFormat: string): typeof transports.Console {
    if (logFormat === 'json') {
        return new transports.Console({
            format: format.json(),
        });
    }

    return new transports.Console({
        format: format.combine(
            format.colorize(),
            format.timestamp(),
            format.printf((info) => {
                const { level, message, timestamp, ...rest } = info;

                let suffix: string = '';
                if (rest) {
                    for (const [key, value] of Object.entries(rest)) {
                        suffix += `${EOL}\t${key} - ${JSON.stringify(value)}`;
                    }
                }

                return `${timestamp} [${level}]: ${message}${suffix}`;
            }),
        ),
    });
}

const dataDogTransport = (params: transports.HttpTransportOptions): typeof transports.Http => {
    const queryParams = new URLSearchParams({
        'dd-api-key': process.env.DD_RUM_TOKEN as string,
        ddsource: 'nodejs',
        service: 'sb-shell'
    });

    return new transports.Http({
        host: 'http-intake.logs.datadoghq.com',
        ssl: true,
        path: `/api/v2/logs?${queryParams}`,
        ...params,
    });
}
